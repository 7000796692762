import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="visits--visit-page"
export default class extends Controller {
  static values = {
    sortPreference: String,
    visitId: String
  }
  
  connect(){
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }

  clearTimepointModal(event) {
    event.preventDefault();

    if (!event.target.classList.contains("disabled")) {
      const visit_id = event.target.dataset.visitId
      const timepoint_id = event.target.dataset.sampleTimepointId
      const ffs = event.target.dataset.ffs
      $.ajax({
        type: "GET",
        url: `/subject_visits/${visit_id}/clear_timepoint_modal/?sample_timepoint_id=${timepoint_id}&ffs=${ffs}`,
        dataType: "script"
      });
    }
  }

  clearUrlParams(e){
    const url = new URL(`${window.location.href.split('?')[0]}`);
    window.Turbo.navigator.history.replace(url);
  }

  loadTimepoint(event){
    if (!event.target.classList.contains("disabled")) {
      $(".timepoint-indicator-active").removeClass("timepoint-indicator-active")
      event.target.closest("div.timepoint-selector").classList.add("timepoint-indicator-active")
    }
    const row = $(event.target).closest('.timepoint-selector')
    const timepoint_id = row.attr('data-timepoint-id')
    const visit_id = row.attr('data-visit-id')
    const language_id = row.attr('data-language-id')

    const timpointWindowLink = document.querySelector(`[data-timepoint-id='${timepoint_id}'] > div > .timepoint-window-link`)
    
    if (timpointWindowLink !== null && timpointWindowLink.matches(':hover')) {
      return
    }
 
    get(`/subject_visits/${visit_id}/load_timepoint?sample_timepoint_id=${timepoint_id}&selected_language_id=${language_id}&sort_preference=${this.sortPreferenceValue}`, { responseKind: 'turbo-stream' })
    
    // Inject fade out animation on removal of timepoint from the page
    document.addEventListener("turbo:before-stream-render", async (event) => {
      let elementToRemove = document.getElementById(event.target.target)
      if (elementToRemove) {
        // use the data-stream-exit-class attribute to get the animation classname
        let streamExitClass = elementToRemove.dataset.streamExitClass
        if (streamExitClass) {
          event.preventDefault()
          elementToRemove.classList.add(streamExitClass);
          elementToRemove.addEventListener('animationend', function() {
            event.target.performAction();
          })
        }
      }
    }, {once: true})
  }

  sortTimepoints(event){
    const sort_by = event.target.dataset.sortBy // what the user wants to sort by now
    const visit_id = event.target.dataset.visitId
    const timepoint_id = event.target.dataset.timepointId
    Array.prototype.forEach.call(document.getElementsByClassName("sort-preference-input"), function(element) {
      element.setAttribute("value", sort_by)
    });
    get(`/subject_visits/${visit_id}/sort_timepoints?sample_timepoint_id=${timepoint_id}&sort_by=${sort_by}`, { responseKind: 'turbo-stream' })
  }
}
