import { now } from "jquery";

export function initializeTimepointRow() {
  $(".datepicker").datepicker();
  $('[data-toggle="tooltip"]').tooltip();

  initializeTimepickers();
  updateTimepointDateFields();
}

export function initializeTimepickers() {
  // gogo timepoint timepickers
  $(".slsm-timepoint-timepicker"
  ).timepicker({
    showMeridian: false,
    showSeconds: false,
    defaultTime: false,
    minuteStep: 1,
    icons: {
      up: "fas fa-chevron-up",
      down: "fas fa-chevron-down",
    },
  });

  $(
    ".usm-timepoint-current .usm-timepoint-timepicker, .usm-timepoint-timepicker"
  ).timepicker({
    showMeridian: false,
    showSeconds: false,
    minuteStep: 1,
    icons: {
      up: "fas fa-chevron-up",
      down: "fas fa-chevron-down",
    },
  });

  $(
    ".timepoint-current .timepoint-timepicker, .timepoint-edit .timepoint-timepicker"
  ).timepicker({
    showMeridian: false,
    showSeconds: true,
    minuteStep: 1,
    secondStep: 10,
    icons: {
      up: "fas fa-chevron-up",
      down: "fas fa-chevron-down",
    },
  });
}

export function updateTimepointDateFields() {
  $(".subject_visit_scheduled_at_date").bind("ajax:success", function (
    event,
    data,
    status,
    xhr
  ) {
    var timepoint_dates = jQuery.parseJSON(data);
    for (const timepoint in timepoint_dates) {
      $(`#${timepoint}`).val(timepoint_dates[timepoint])
    }
  });
}

export function clearTimepointBarcodeField() {
  //Target the text in the field with the unique id
  let idToTarget = $(this).attr("id").toString();

  let theKey = idToTarget.toString();
  localStorage.removeItem(theKey);
}

export function initializeTimepointBarcodeField() {
  var $self = $(this);

  if (typeof Storage !== "undefined") {
    //Target the text in the field with the unique id
    let idToTarget = $(this).attr("id").toString();

    //grab the stringified value from local storage
    const itemStr = localStorage.getItem(idToTarget);

    //jump out if there is no value
    if (itemStr) {
      let item = JSON.parse(itemStr);

      //remove expired items
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(item.reference);
      }

      //set the value from local storage to the field we are targeting
      if ($self.hasClass("sample-not-collected")) {
        document.getElementById(idToTarget).value = null;
      } else if (!$self.hasClass("timepoint-row-readonly") && !$self.disabled) {
        document.getElementById(idToTarget).value = item.value;
      }
    }
    
    // Locally save the barcodes a user typed but never submitted
    function SaveUnsubmittedBarcodes(){
      const now = new Date();
      let myInput = document.getElementById(idToTarget).value;

      const storedBarcodeAndTime = {
        reference: idToTarget.toString(),
        value: myInput,
        expiry: now.getTime() + 300000,
      };

      let theKey = idToTarget.toString();
      let theValue = JSON.stringify(storedBarcodeAndTime);
      
      localStorage.setItem(theKey, theValue);
    }
    document.getElementById(idToTarget).addEventListener("keyup", SaveUnsubmittedBarcodes);
    document.getElementById(idToTarget).addEventListener("change", SaveUnsubmittedBarcodes); 

    //Not Collected Dropdown (present for acs & usm versions of page)
    if ($(`[name^=${idToTarget}_collection_status]`).length > 0){
      let dropdownIdToTarget = $(`[name^=${idToTarget}_collection_status]`)[0].id;

      const dropdownStr = localStorage.getItem(dropdownIdToTarget);
      if (dropdownStr) {
        let item = JSON.parse(dropdownStr);

        //remove expired items
        const now = new Date();
        if (now.getTime() > item.expiry) {
          localStorage.removeItem(item.reference);
        }

        //set the value from local storage to the field we are targeting
        if(item.value === "Not Collected"){
          document.getElementById(dropdownIdToTarget).value = item.value;
          $(`#${idToTarget}`)[0].setAttribute("disabled","true");
          $(`#${idToTarget}`)[0].value = "Not Collected"
          $(`#${idToTarget}`)[0].setAttribute("placeholder","Not Collected")
        }
      }


      function SaveNotCollectedDropdown(){
        const now = new Date();
        let myInput = document.getElementById(dropdownIdToTarget).value;
        
        const storedBarcodeAndTime = {
          reference: dropdownIdToTarget.toString(),
          value: myInput,
          expiry: now.getTime() + 300000,
        };
        
        let theKey = dropdownIdToTarget.toString();
        let theValue = JSON.stringify(storedBarcodeAndTime);

        localStorage.setItem(theKey, theValue);
      }
      
      //Not Collected Dropdown
      document.getElementById(dropdownIdToTarget).addEventListener("change", SaveNotCollectedDropdown);
    }
  }

  if ($self.hasClass("sample-not-collected")) {
    var placeholder = "Not Collected";
  } else {
    var placeholder = "Scan or type";
  }

  var options = {
    // autoFocus: true,
    placeholder: placeholder,
    adjustWidth: false,
    getValue: "value",
    url: function (phrase) {
      var sample_group_id = $self
        .parent()
        .siblings(".sample_group_id")
        .data("sample-group-id");
      return (
        "/sample_groups/" +
        sample_group_id +
        "/fetch_barcodes?v=" +
        phrase
      );
    },
    listLocation: "barcodes",
    list: {
      maxNumberOfElements: 20,
      match: {
        enabled: true,
      },
      onChooseEvent: function () {},
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $self.easyAutocomplete(options);

  // Fixing markup for autocomplete
  $self.closest(".input-group").each(function (i, inputGroup) {
    $(inputGroup).removeClass("input-group input-group-sm");
    var autocomplete = $(inputGroup).find(".easy-autocomplete");
    $(inputGroup).find(".input-group-prepend").prependTo(autocomplete);
    $(inputGroup).find(".skip-dropdown").appendTo(autocomplete);
    autocomplete.addClass("input-group input-group-sm");
  });

  $('[data-toggle="tooltip"]').on("click", function () {
    $(this).tooltip("hide");
  });
}

export function initializeAliquotNoteField(targetElement) {
  initializeFieldSaver($(targetElement))
}

function initializeFieldSaver(targetElement) {
  if (typeof Storage !== "undefined") {
    //Target the text in the field with the unique id
    let idToTarget = targetElement.attr("id").toString();

    //grab the stringified value from local storage
    const itemStr = localStorage.getItem(idToTarget);

    //jump out if there is no value
    if (itemStr) {
      let item = JSON.parse(itemStr);

      //remove expired items
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(item.reference);
      }

      //set the value from local storage to the field we are targeting
      if (targetElement.hasClass("sample-not-collected")) {
        document.getElementById(idToTarget).value = null;
      } else {
        document.getElementById(idToTarget).value = item.value;
      }
    }
    
    // Locally save the field values 
    function SaveFields(){
      const now = new Date();
      let myInput = document.getElementById(idToTarget).value;

      const storedFieldAndTime = {
        reference: idToTarget.toString(),
        value: myInput,
        expiry: now.getTime() + 300000,
      };

      let theKey = idToTarget.toString();
      let theValue = JSON.stringify(storedFieldAndTime);
      
      localStorage.setItem(theKey, theValue);
    }
    document.getElementById(idToTarget).addEventListener("keyup", SaveFields);
    document.getElementById(idToTarget).addEventListener("change", SaveFields);
  }
}
