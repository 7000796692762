import { Controller } from "@hotwired/stimulus"
import { initializeAliquotNoteField } from "../../src/common/common";
export default class extends Controller {
  static get targets() {
    return ["aliquotNote"]
  }

  connect(){
    $(".autocomplete-barcode-field").each(window.initializeTimepointBarcodeField);
    this.aliquotNoteTargets.forEach(initializeAliquotNoteField); 
    
    $("#clear_timepoint_modal").modal('hide')

    if($("#identification_barcode_detected").val() == "true"){
      $('#identification_barcode_detected_modal').modal();
    }
  }

  toggleCollapseIcon(e){
    var step_id = e.target.getAttribute("data-step-id")
    $(`#toggle-icon-${step_id}`).toggleClass('fa-chevron-up');
    $(`#toggle-icon-${step_id}`).toggleClass('fa-chevron-down');
  }

  toggleCollectedStatus(event){
    $(".not-collected-select").on('change', function(e){
      var sample_group_id = e.target.getAttribute("data-sample-group-id")
      var timepoint_id = e.target.getAttribute("data-timepoint-id")
      var label_for = e.target.getAttribute("name")
      if($(e.target).prop('checked')){
        $(`.autocomplete-barcode-field[data-sample-group-id='${sample_group_id}'][data-timepoint-id='${timepoint_id}']`).removeClass(" d-none");
        $(`.autocomplete-barcode-field[data-sample-group-id='${sample_group_id}'][data-timepoint-id='${timepoint_id}']`).attr("placeholder", "Scan or type");
        $(`#timepoint_${timepoint_id}_sample_group_${sample_group_id}_not_collected_reason`).addClass("d-none");
        $(`select[name="timepoint_${timepoint_id}_sample_group_${sample_group_id}_reason"]`).prop('required', false);
        $(`#timepoint_${timepoint_id}_sample_group_${sample_group_id}_label`).removeClass("d-none");
        $(`label[for='${label_for}']`).text("Collected")

        $(`.timepoint-meta-field[data-timepoint-id='${timepoint_id}']`).attr("disabled", false);
      } else {
        $(`.autocomplete-barcode-field[data-sample-group-id='${sample_group_id}'][data-timepoint-id='${timepoint_id}']`).addClass("d-none");
        $(`.autocomplete-barcode-field[data-sample-group-id='${sample_group_id}'][data-timepoint-id='${timepoint_id}']`).val("");
        $(`.autocomplete-barcode-field[data-sample-group-id='${sample_group_id}'][data-timepoint-id='${timepoint_id}']`).attr("required", false);
        $(`#timepoint_${timepoint_id}_sample_group_${sample_group_id}_not_collected_reason`).removeClass("d-none");
        $(`select[name="timepoint_${timepoint_id}_sample_group_${sample_group_id}_reason"]`).prop('required', true);
        $(`#timepoint_${timepoint_id}_sample_group_${sample_group_id}_label`).addClass("d-none");
        $(`label[for='${label_for}']`).text("Not collected")
      }
    });
  }
  
  clearTimepointModal(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const visit_id = event.target.dataset.visitId
      const timepoint_id = event.target.dataset.sampleTimepointId
      const ffs = event.target.dataset.ffs
      const selected_language_id = event.target.dataset.selectedLanguageId

      $.ajax({
        type: "GET",
        url: `/subject_visits/${visit_id}/clear_timepoint_modal/?sample_timepoint_id=${timepoint_id}&ffs=${ffs}&selected_language_id=${selected_language_id}`,
        dataType: "script"
      });
      $(event.target.closest("div.procedure-step")).find(".autocomplete-barcode-field").each((index, barcodeField) => {
        $(barcodeField)[0].dispatchEvent(new Event("keyup"));
      })
      $(event.target.closest("div.procedure-step")).find(".aliquot-notes").each((index, notesField) => {
        $(notesField)[0].dispatchEvent(new Event("keyup"));
      })
    }
  }

  generateRandomBarcodes(event){
    if (!event.target.classList.contains("disabled")) {
      $(event.target.closest("div.procedure-step")).find(".autocomplete-barcode-field").each((index, barcodeField) => {
        if ($(barcodeField)[0].value.trim() == "" && $(barcodeField)[0].placeholder == "Scan or type") {
          $(barcodeField)[0].value = `RB-${Math.random().toString(36).substring(2,10)}` 
          $(barcodeField)[0].dispatchEvent(new Event("keyup"));
        } 
      })
    }
  }
}
