import RowCheckController from "./row_check_controller";

export default class extends RowCheckController {
  static targets = ["buttonsDiv", "createShipmentButton"];

  createNewShipment(event) {
    event.preventDefault();

    if (!event.target.classList.contains("disabled")) {
      const loader = document.createElement('button');
      loader.disabled = true;
      loader.classList.add('btn', 'btn-sm', 'btn-secondary');
  
      const spinner = document.createElement('span');
      spinner.classList.add('spinner-border', 'spinner-border-sm', 'mr-2');
      const text = document.createTextNode('Creating Shipment...');
  
      loader.replaceChildren(spinner, text);
  
      this.createShipmentButtonTarget.replaceWith(loader);

      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            const sample_ids = [...ids].join(",");
            const sample_id = event.target.dataset.sampleId             
            $.ajax({
              type: "POST",
              url: `/sample_shipments/create_sample_shipment/?sample_ids=${sample_ids}&sample_id=${sample_id}${sample_ids}`,
              data: {redirect_to: window.location.href},
              dataType: "script"
            });
          },
        },
      });
    }
  }

  addToPendingShipment(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      const loader = document.createElement('button');
      loader.disabled = true;
      loader.classList.add('btn', 'btn-sm', 'btn-secondary');

      const spinner = document.createElement('span');
      spinner.classList.add('spinner-border', 'spinner-border-sm', 'mr-2');
      const text = document.createTextNode('Adding to Shipment...');

      loader.replaceChildren(spinner, text);

      event.target.replaceWith(loader);
    }

    this.dispatch("submit", {
      detail: {
        perform: function (ids) {
          const sample_ids = [...ids].join(",");
          const sample_id = event.target.dataset.sampleId
          const shipment_id = event.target.dataset.shipmentId            
          $.ajax({
            type: "POST",
            url: `/sample_shipments/add_sample_to_existing_shipment/?sample_ids=${sample_ids}&sample_id=${sample_id}&shipment_id=${shipment_id}`,
            data: {redirect_to:  window.location.href},
            dataType: "script"
          });
        },
      },
    });
  }

  choosePendingShipment(event) {
    event.preventDefault();
    
    if (!event.target.classList.contains("disabled")) {
      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            const sample_id = event.target.dataset.sampleId
            const sample_ids = [...ids].join(",");
            $.ajax({
              type: "GET",
              url: `/sample_shipments/fetch_pending_shipments/?sample_ids=${sample_ids}&sample_id=${sample_id}`,
              dataType: "script"
            });
          },
        },
      });
    }
  }

  storageRecord(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            // const sample_ids = [...ids].join(",");
            const sample_ids = [...ids].join(",");
            const sample_id = event.target.getAttribute('data-sample-id')
            var redirect_target = location.href;
            if ($("#turbo-table-filter-form") == null) {
              redirect_target = $("#turbo-table-filter-form").action
            }
            $.ajax({
              type: "GET",
              url: `/samples/${sample_id}/storage_record_modal?sample_ids=${sample_ids}`,
              data: {redirect_to: redirect_target},
              dataType: "script"
            });
          },
        },
      });
    }
  }

  incinerateSamples(event) {
    event.preventDefault();
    if (!event.target.classList.contains("disabled")) {
      this.dispatch("submit", {
        detail: {
          perform: function (ids) {
            const sample_id = event.target.getAttribute('data-sample-id')
            $.ajax({
              type: "GET",
              url: `/samples/${sample_id}/incinerate_samples_modal?redirect_to=${location.href}`,
              dataType: "script"
            });
          },
        },
      });
    }
  }

  checkedRows(event) {
    const ids = Array.from(event.detail.ids);
    if (ids.length == 0) {
      $("#add_to_shipment_button").hide();
      $("#cannot_commingle").hide();
      $("#store_multiple_button").hide();
      $("#cannot_store").hide();
      $("#cannot_ship_or_store").hide();
      $(".sample-row-action-button").removeClass('disabled')
    } else {
      $(".sample-row-action-button").addClass('disabled')
      $.ajax({
        type: "GET",
        url: `/samples/check_shipping_compatibility?sample_ids=${ids}&workspace_id=${    this.buttonsDivTarget.dataset.workspaceId}`,
        // data: data,
        dataType: "script",
      });
    }
  }

}
